<template>
    <div class="serenata" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'phone' : ''">
        <div class="cntSerenata">
            <p style="align-self: center">
                <strong>In questa sezione troverete le canzoni che verranno eseguite durante la serenata, se vorrete aiutare lo sposo.</strong>
            </p>
            <div class="cntCanzoni">
                <v-progress-circular style="margin-top: 2rem; align-self: center" v-if="!canzoni.length" :size="30" :width="4" color="black" indeterminate></v-progress-circular>
                <ol style="margin-bottom: 50px;">
                    <li class="canzone" v-for="(canzone, index) in canzoni" :key="index">
                        <strong>{{ canzone.Titolo }}</strong> - {{ canzone.Autore }}
                        <div style="display: flex">
                            <p style="cursor: pointer; text-decoration: underline; margin-block-end: 0;" @click="toCanzone(canzone, false)">Testo</p>
                            <p style="margin-left: 10px; cursor: pointer; text-decoration: underline; margin-block-end: 0" v-if="canzone.Traduzione" @click="toCanzone(canzone, true)">
                                Traduzione
                            </p>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
import googleSheets from "../scripts/googleSheets";
export default {
    async mounted() {
        this.canzoni = await googleSheets.getTestiCanzoni();
    },

    data() {
        return {
            canzoni: []
        };
    },

    methods: {
        toCanzone(canzone, traduzione) {
            this.$router.push({ name: "canzone", query: { title: canzone.Titolo, traduzione: traduzione } });
        }
    }
};
</script>

<style lang="scss" scoped>
.serenata {
    background: linear-gradient(180deg, rgb(129, 148, 255) 0%, rgb(166, 198, 245) 35%, rgb(191, 224, 255) 100%);
    height: 100%;
    .cntSerenata {
        margin: 2rem 8px 0 8px;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        .cntCanzoni {
            display: flex;
            flex-direction: column;
            .canzone {
                margin: 1rem 0;
                border: 1px solid black;
                padding: 5px;
                border-radius: 5px;
            }
        }
    }
}

.serenata.phone {
    .cntSerenata {
        margin-top: 70px;
    }
}
</style>
